<template>
  <main class="main">
    <section class="left">
      <div class="login_outer">
        <div class="login_inner">
          <div>
            <h1 class="text-2xl font-bold">登入</h1>
            <h2>商家後台</h2>
          </div>

          <vs-alert v-if="login_errorMsg" color="danger" gradient>
            <template #title>發生錯誤，請稍候再試 😵</template>
            {{ login_errorMsg }}
          </vs-alert>

          <div>
            <ValidationObserver class="space-y-6" v-slot="{ invalid }">
              <div class="relative">
                <ValidationProvider name="電子信箱" rules="required|email" v-slot="{ errors }">
                  <vs-input type="email" v-model="account" placeholder="電子信箱">
                    <template #icon>
                      <svg-icon icon-class="email" style="fill: black"></svg-icon>
                    </template>
                  </vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>

              <div class="relative">
                <ValidationProvider mode="aggressive" name="密碼" rules="required|password" v-slot="{ errors }">
                  <vs-input
                    @keydown.enter="login(invalid)"
                    type="password"
                    v-model="password"
                    placeholder="密碼"
                    :visiblePassword="hasVisiblePassword"
                    @click-icon="hasVisiblePassword = !hasVisiblePassword"
                  >
                    <template #icon>
                      <svg-icon v-if="password === ''" icon-class="password" style="fill: black"></svg-icon>
                      <svg-icon v-else-if="hasVisiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                      <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
                    </template>
                  </vs-input>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>

              <div class="text-sm flex items-start justify-between">
                <div class="space-x-5 text-gray-text_light">
                  <router-link :to="{ name: 'register' }">註冊</router-link>
                  <button class="hover:underline" @click="dialog_toggle(true)">忘記密碼？</button>
                </div>
                <vs-button
                  :disabled="invalid"
                  class="font-medium"
                  ref="login"
                  size="xl"
                  style="border-radius: 12px"
                  color="rgb(125, 138, 249)"
                  @click="login(invalid)"
                  >登入</vs-button
                >
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>

    <section class="right"></section>

    <!-- 忘記密碼 dialog -->
    <vs-dialog blur v-model="forgotPassword_dialogActive" @close="dialog_toggle(false)">
      <template #header>
        <span class="text-lg font-medium text-gray-text_dark">忘記密碼？</span>
      </template>

      <div class="space-y-6">
        <h6 class="text-sm text-gray-text_light text-center">請輸入註冊時登記的電子信箱，我們將協助您重設密碼</h6>
        <vs-alert v-if="forgotPassword_errorMsg" color="danger" gradient>
          <template #title>發生錯誤，請稍候再試 😵</template>
          {{ forgotPassword_errorMsg }}
        </vs-alert>
        <div class="space-y-6">
          <ValidationObserver class="space-y-6" v-slot="{ invalid }">
            <div class="relative">
              <ValidationProvider mode="aggressive" name="電子信箱" rules="required|email" v-slot="{ errors }">
                <vs-input @keydown.enter="sent_findPasswordEmail(invalid)" v-model="forgotPassword_email" placeholder="電子信箱">
                  <template #icon>
                    <svg-icon icon-class="email" style="fill: black"></svg-icon>
                  </template>
                </vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>

            <vs-button
              :disabled="invalid"
              class="font-medium w-full rounded-xl h-10"
              ref="sent"
              color="rgb(125, 138, 249)"
              @click="sent_findPasswordEmail(invalid)"
            >
              <span class="text-base">寄出重設密碼信件</span></vs-button
            >
          </ValidationObserver>
        </div>
      </div>
    </vs-dialog>
  </main>
</template>

<script>
export default {
  data() {
    return {
      account: '', // 登入帳號
      password: '', // 登入密碼
      hasVisiblePassword: false, // 是否開啟密碼預覽
      forgotPassword_dialogActive: false, // 忘記密碼 dialog
      forgotPassword_email: '', // 忘記密碼帳號
      login_errorMsg: '', // 登入錯誤訊息
      forgotPassword_errorMsg: '', // 忘記密碼錯誤訊息
    }
  },
  computed: {
    _userDevice() {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android終端
      let isiOS = /(iPhone|iPad|iPod|iOS)/.test(u)
      return isAndroid ? 1 : isiOS ? 2 : 0
    },
  },
  methods: {
    login(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.login,
        scale: '0.6',
        background: 'rgb(125, 138, 249)',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        // method: 'post',
        url: 'front/set/slogin/getLogin.php',
        headers: {
          account: this.account,
          pawd: this.password,
        },
        data: {
          lang: 0,
          device: this._userDevice,
        },
      })
        .then((res) => {
          if (res.data.Success) {
            // 關閉按鈕loading
            loading.close()

            // 儲存token
            this.$store.commit('login/UPDATE_COOKIE', res.data.Data)

            return res.data.Data.stoken
          } else {
            this.login_errorMsg = res.data.Message
            loading.close()
          }
        })
        .then((stoken) => {
          if (!stoken) return
          // 1.3.1 讀取網站參數
          this.$axios({
            url: 'front/overview/getWebParam.php',
            headers: {
              stoken,
            },
            kcval: true,
            data: {
              lang: 0,
            },
          }).then((res) => {
            if (res.data.Success) {
              // 儲存網站參數
              this.$store.commit('getWebData/UPDATE_COOKIE', res.data.Data)

              // 若有附帶導航網址
              if (this.$route.query.redirect) {
                this.$router.replace({
                  path: this.$route.query.redirect,
                })
              } else {
                this.$router.replace({
                  name: 'admin',
                })
              }
            }
          })
        })
    },
    sent_findPasswordEmail(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.sent,
        scale: '0.6',
        background: 'rgb(125, 138, 249)',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/set/slogin/setForgetMail.php',
        headers: {
          account: this.forgotPassword_email,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.forgotPassword_email = ''
          this.forgotPassword_dialogActive = false
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadOrder()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    dialog_toggle(value) {
      let query = this.$route.query
      if (value) {
        this.forgotPassword_dialogActive = true // 打開dialog
        // 新增參數
        this.$router.replace({
          query: { ...query, forgotPassword_dialogActive: true },
        })
      } else {
        // 刪除參數需要用到深拷貝
        // 將物件轉成字串再轉成物件，這樣就真的可以確保出來的會是一個新的物件而且是使用不同的記憶體。
        let newQuery = JSON.parse(JSON.stringify(this.$route.query)) // 深拷貝
        delete newQuery.forgotPassword_dialogActive // 刪除
        this.$router.replace({ query: newQuery }) // 再覆蓋
      }
    },
  },
  mounted() {
    // 開啟 忘記密碼 dialog 的判斷
    if (this.$route.query.forgotPassword_dialogActive) this.forgotPassword_dialogActive = true
  },
}
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: underline;
}

.main {
  @apply flex flex-col lg:flex-row h-screen text-gray-text_dark;
}

.left {
  @apply h-full relative flex-shrink-0 flex-grow
  flex items-center justify-center;

  background: url('../assets/images/login/bg1.jpg') center/cover;

  @screen lg {
    min-width: 450px;
    background: white;
  }
}

.login_outer {
  @apply bg-white
    rounded-2xl lg:absolute 
    p-6 shadow-2xl
    flex items-center justify-center
    lg:top-1/2 lg:-right-1/3 lg:transform lg:-translate-y-1/2
    w-full h-auto m-3 sm:m-0;

  background: rgba(255, 255, 255, 1);
  // backdrop-filter: blur(5px);

  @screen sm {
    width: 400px;
    height: 500px;
  }

  .login_inner {
    @apply w-full space-y-6;
  }
}

.right {
  @apply h-full w-full lg:w-16/20 hidden lg:block;
  background: url('../assets/images/login/bg1.jpg') center/cover;
}

::v-deep .vs-input {
  border: none;
  background: rgba(var(--vs-gray-2), 1);
}
</style>
